<template>
  <section class="card">
    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header" >
        <span class="selecionado" v-html="selecionado">
        </span>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <div :class="'h-100 '+ (filtros.visible ? 'col-lg-10 p0' : 'col-lg-12')">
        <div :class="'card-body card-tabela ' + (filtros.visible ? '' : 'p0')">
          <div class="h-100">
            <div :class="'table-responsive records'">
              <table class="table table-bordered table-hover mb-0 table-sm" v-if="result.Records && result.Records.length">
                <thead>
                  <tr>
                    <template v-for="(c) in result.Columns.filter(c => c.Display)">
                      <th :key="c"
                          @click="ordenar(c.Order)">
                        <span>
                          {{c.Display}}
                        </span>
                        <span class="pull-right">
                          <i v-if="params.order == c.Order"
                             :class="'el ' + (params.direction == 'desc' ? 'el-chevron-down' : 'el-chevron-up')">

                          </i>
                        </span>
                      </th>
                    </template>
                    
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item) in result.Records">
                    <tr :key="item.Id"
                        @click="rowClick(item)">
                      <template v-for="(c) in result.Columns.filter(c => c.Display)">
                        <td :key="c.Property">
                          <table v-if="c.Property == 'ValorNovo'"
                                 class="table table-sm">
                            <thead>
                              <tr>
                                <th>Campo</th>
                                <th>Valor antigo</th>
                                <th>Valor novo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(v, vindex) in JSON.parse(item[c.Property])">
                                <tr :key="v" v-if="vindex != 'DataAlteracao' && vindex != 'DataAlteracao' && vindex != 'DataCadastro' && vindex != 'UsuarioCadastro' && vindex != 'UsuarioAlteracao' ">
                                  <td>{{vindex}}</td>
                                  <td>
                                    {{ item['ValorAntigo'] ? JSON.parse(item['ValorAntigo'])[vindex] : ''}}
                                  </td>
                                  <td>
                                    {{v}}
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                          <span v-if="c.Property.indexOf('Valor') == -1 && c.Property != 'PrimaryKeyRegistro'">
                            {{item[c.Property]}}
                          </span>
                        </td>

                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

            <pagination :pagination="result.Pagination"
                        :params="params"
                        @trocar-pagina="(page) => { handleConsultar(page) }">
            </pagination>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/services/api.js';
import consultar from '@/services/consultar.js';

//LOGS
export default {

    components: {
        ...consultar.components,
        
    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/configuracoes/logs',
            clone: '',
            obj: {},
            filtros: {
                data: [{
                        id: 'tabela',
                        title: 'Tabela',
                        active: false,
                        source: that.tableNames,//@Html.Raw(JsonConvert.SerializeObject(ViewBag.tables)),
                        sourceType: 'radio'

                    },
                    {
                        id: 'primaryKey',
                        title: 'Id',
                        active: false,
                        type: 'number'
                    },
                    {
                        id: 'termo',
                        title: 'Valor ou campo',
                        active: false,
                    },
                    {
                        id: 'usuario',
                        title: 'Usuário alteração',
                        active: false,
                    },
                    {
                        id: 'dataInicio',
                        title: 'Data Início',
                        active: false,
                        type: 'date'
                    },
                    {
                        id: 'dataFim',
                        title: 'Data Fim',
                        active: false,
                        type: 'date'
                    },
                ],
                visao: [{
                    id: 0,
                    title: 'Todos Logs',
                    filtro: []
                }, ],
                visaoSelecionada: 0,
                visible: true
            },

        };
    },

    methods: {
        ...consultar.methods,
    },
    mounted: consultar.mounted,

    beforeMount: function () {
        let that = this;
        api.get(this.url + '?tableNames=true')
            .then(response => {
                that.filtros.data.filter(f=>f.id === 'tabela')[0].source = response.data;
                that.tableNames = response.data;
            });
    }
};
</script>
